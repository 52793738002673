<template>
  <div class="app-logo">
    <div class="logo-center">

      <svg id="Ebene_2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 465.87 150.96" :style="{fill: color}">
        <g>
          <path class="cls-1"
                d="M50.32,.85C21.68,3.38,.17,28.29,.17,57.04v59.1c0,4.43,3.59,8.02,8.02,8.02s8.02-3.59,8.02-8.02v-21.24c10.34,10.36,24.51,16.27,39.14,16.27,30.53,0,55.37-24.84,55.37-55.38C110.72,23.72,83.11-2.05,50.32,.85Zm43.62,62.7c-3,15.46-15.37,27.84-30.84,30.84-26.68,5.18-49.88-17.09-46.58-43.63,2.18-17.48,16.3-31.61,33.78-33.78,26.55-3.3,48.81,19.9,43.64,46.58Z"/>
          <path class="cls-1"
                d="M8.11,134.75c-4.47,0-8.11,3.64-8.11,8.11,0,2.26,.88,4.35,2.47,5.88,1.51,1.45,3.5,2.23,5.63,2.23,.13,0,.25,0,.38,0,3.36-.15,6.39-2.48,7.37-5.67,.77-2.51,.32-5.15-1.23-7.24-1.55-2.09-3.92-3.3-6.52-3.3Z"/>
          <path class="cls-1"
                d="M282.38,.03c-29.8,.99-54.05,26.69-54.05,57.28v45.46c0,4.48,3.64,8.12,8.13,8.12s8.12-3.64,8.12-8.12V56.16c0-21.56,17.44-39.46,38.87-39.91,10.38-.23,20.42,3.8,28.1,11.32,7.91,7.75,12.45,18.48,12.45,29.46v45.74c0,4.48,3.64,8.12,8.12,8.12s8.12-3.64,8.12-8.12V56.16c0-15.39-6.09-29.75-17.14-40.45C312.11,5.09,297.64-.48,282.38,.03Z"/>
          <path class="cls-1"
                d="M174.76,94.23c-19.09-3.18-32.94-19.5-32.94-38.8l-.05-46.85c0-4.42-3.6-8.02-8.02-8.02s-8.02,3.6-8.02,8.02l.05,46.85c0,27.34,19.49,50.33,46.34,54.67,.45,.07,.89,.11,1.33,.11,1.94,0,3.82-.68,5.31-1.95,1.79-1.52,2.81-3.74,2.81-6.09,0-3.94-2.87-7.28-6.82-7.94Z"/>
          <path class="cls-1"
                d="M204.96,28.72c-4.58,0-8.31,3.47-8.31,7.73V103.26c0,4.59,3.72,8.31,8.31,8.31s8.31-3.72,8.31-8.31V36.45c0-4.26-3.73-7.73-8.31-7.73Z"/>
          <path class="cls-1"
                d="M205.14,16.84c.13,0,.25,0,.38,0,3.36-.14,6.39-2.48,7.37-5.67,.77-2.51,.32-5.15-1.23-7.24-1.55-2.09-3.92-3.3-6.52-3.3-4.47,0-8.11,3.64-8.11,8.11,0,2.26,.88,4.35,2.47,5.88,1.51,1.45,3.5,2.23,5.63,2.23Z"/>
          <path class="cls-1"
                d="M465.85,62.06v-6.27c0-30.42-24.75-55.17-55.18-55.17s-55.37,24.75-55.37,55.17,24.84,55.38,55.37,55.38c14.63,0,28.8-5.91,39.13-16.26-.52,21.02-17.95,39.22-39.12,39.41h-.61s0,0,0,0c-4.01,.02-7.37,2.86-7.99,6.73-.37,2.31,.29,4.65,1.81,6.43,1.54,1.8,3.79,2.84,6.18,2.87h0s.06,0,.06,0c.01,0,.03,0,.04,0h0s.52,0,.52,0c29.79,0,53.88-24.29,55.11-54.08l.05-1.81c0-.17,.01-.35,.01-.52v-31.66c0-.07,0-.14-.02-.22Zm-55.18,33.08c-21.69,0-39.34-17.65-39.34-39.34s17.65-39.14,39.34-39.14,39.14,17.56,39.14,39.14-17.56,39.34-39.14,39.34Z"/>
        </g>
      </svg>


    </div>
  </div>
</template>

<script>
export default {
  name: "AppLogo",


  props: {


    color: {

      default: 'rgba(30,100,170,1)'//'#0171BA'

    }

  },
}

</script>

<style lang="scss" scoped>

.app-logo {
  width: 100%;
  max-width: 130px;
  margin: 15px auto;
  display: block;

  svg {
    width: 100%;
    display: block;
  }

}


</style>
