<template>


  <v-main class="preloader-view text-center">
    <div style="width:100px; margin: 0 auto; position: fixed; bottom: 20px; right: 20px;">

      <v-progress-circular indeterminate color="primary"></v-progress-circular>
      <app-logo></app-logo>

    </div>
  </v-main>


</template>


<script>

import appLogo from "./AppLogo";

export default {


  components: {
    appLogo
  }


}

</script>


<style lang="scss">

.theme--light {
  .preloader-view {
    background-color: rgba(255,255,255,0.5);
  }
}


.theme--dark {
  .preloader-view {
    background-color: rgba(0,0,0,0.5);
  }
}

</style>