<template>


  <div class="text-center">

    <v-dialog v-model="dialog" max-width="1200">

      <template v-slot:activator="{ on, attrs }">
        <v-btn icon v-on="on">
          <v-icon color="info" v-if="active_jobs.length > 0" :class="{'pulse': active_jobs.length > 0}">mdi-database-sync</v-icon>
          <v-icon v-else-if="session.export_jobs.length == 0">mdi-database</v-icon>
          <v-icon color="info" v-else>mdi-database-check</v-icon>
        </v-btn>
      </template>

      <v-card>
        <v-card-title>
          {{ $t('export-manager.title.export-manager') }}
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog = !dialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-alert type="info" tile class="ma-0" v-if="session.export_jobs.length === 0">
          {{$t('component.background_jobs.message.currently_no_active_jobs')}}
        </v-alert>

        <v-data-table :headers="headers" :items="session.export_jobs" v-else>

          <template v-slot:item.date_created="{item}">

            {{item.date_created|moment($t('erp.common.datetime_string'))}}

          </template>

          <template v-slot:item.date_updated="{item}">

            {{item.date_created|moment($t('erp.common.datetime_string'))}}

          </template>

          <template v-slot:item.progress="{item}">

            {{item.progress}} % <span v-if="item.items_processed != null && item.items_total != null">({{item.items_processed}}/{{item.items_total}})</span>

            <v-progress-linear

                buffer-value="0"
                :value="item.progress"
                reverse
                :stream="item.state === 'in-progress'"
                :indeterminate="item.state === 'aborting'"

            ></v-progress-linear>

          </template>

          <template v-slot:item.actions="{item}">

            <v-btn depressed color="error" tile small @click="abort(item)"><v-icon small>mdi-delete</v-icon></v-btn>
            <v-btn depressed color="success" tile small :disabled="item.download_url === null" @click="download(item)"><v-icon left small>mdi-cloud-download</v-icon> Download</v-btn>

          </template>


        </v-data-table>

      </v-card>
    </v-dialog>

  </div>

</template>

<script>

import {sessionStore} from '@/store/pinia/sessionStore'


export default {

  name: 'user_background_jobs',
  setup() {

    const session = sessionStore();

    return {
      session,
    }

  },

  mounted: function () {

    let self = this;


    this.session.getBackgroundJobs();
    self.backgroundJobInterval = setInterval(function () {
      self.ping()
    }, 5000);

  },

  destroyed: function () {

    let self = this;
    clearInterval(self.backgroundJobInterval);

  },


  data() {
    return {


      dialog: false,
      backgroundJobInterval: null,
      headers: [

        {text: this.$t('export-manager.label.object'), align: 'left', sortable: false, value: 'object'},
        {text: this.$t('export-manager.label.date_created'), align: 'left', sortable: false, value: 'date_created'},
        {text: this.$t('export-manager.label.date_updated'), align: 'left', sortable: false, value: 'date_updated'},
        {text: this.$t('export-manager.label.state'), align: 'left', sortable: false, value: 'state'},
        {text: this.$t('export-manager.label.progress'), align: 'right', sortable: true, value: 'progress'},
        {text: this.$t('export-manager.label.actions'), align: 'right', sortable: false, value: 'actions'},

      ],


    }
  },

  computed: {


    active_jobs: function () {

      let self = this;

      return self.session.export_jobs.filter(jb => jb.state !== 'completed' && jb.state !== 'failed');

    }

  },



  methods: {


    abort(job) {


      let self = this;

      self.$http.delete('/api/v3/search/export/'+job.id).then(() => {

        self.ping();

      });

    },

    download(job) {


      if(job.download_url !== null){
        window.open(job.download_url);
      }

      this.session.getBackgroundJobs();


    },

    ping() {

      this.session.getBackgroundJobs();


    }


  },

}

</script>


<style lang="scss" scoped>


.pulse {

  animation-name: pulse;
  animation-duration: 2s;
  transition: opacity;
  animation-iteration-count: infinite;

}


@keyframes pulse {
  0%   {opacity: 0.3;}
  30%  {opacity: 0.7;}
  40%  {opacity: 0.6;}
  50%  {opacity: 1;}

  100% {opacity: 0.3;}
}

</style>
