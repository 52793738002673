/**
 * Vue Router
 *
 * @library
 *
 * https://router.vuejs.org/en/
 */

// Lib imports
import Vue from 'vue'
import Router from 'vue-router'


// Routes
import paths from './paths'

function route (path, view, name) {
  return {
    name: name || view,
    path,
    component: (resovle) => import(`@/views/${view}.vue`).then(resovle)
  }
}

Vue.use(Router)

// Create a new router
const router = new Router({
  mode: 'history',
  base: process.env.VUE_APP_PATH,
  routes: paths.map(path => route(path.path, path.view, path.name)),
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }
    if (to.hash) {
      return { selector: to.hash }
    }
    return { x: 0, y: 0 }
  }
})


import Meta from 'vue-meta'

Vue.use(Meta,{
  keyName: 'metaInfo', // the component option name that vue-meta looks for meta info on.
      attribute: 'data-vue-meta', // the attribute name vue-meta adds to the tags it observes
      ssrAttribute: 'data-vue-meta-server-rendered', // the attribute name that lets vue-meta know that meta info has already been server-rendered
      tagIDKeyName: 'vmid' // the property name that vue-meta uses to determine whether to overwrite or append a tag
})

export default router
