import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader

Vue.use(Vuetify);


const vuetify = new Vuetify({
    theme: {
        themes: {
            light: {
                primary: '#2064AE',
                secondary: '#FF006E'

            },
            dark: {
                primary: '#FF006E',
                secondary: '#b0bec5'

            },
        },
    },
})

export default vuetify



