<template>


  <v-menu
      v-if="session.user_data !== null"
      transition="slide-y-transition"
      bottom

  >
    <template v-slot:activator="{ on }">

      <v-btn icon v-on="on">
        <v-icon>mdi-account</v-icon>
      </v-btn>

    </template>

    <v-card>

      <v-list dense class="ma-0 pa-0">

        <v-list-item>

          <v-list-item-avatar>
            <v-img :src="session.user_data.avatar"></v-img>

          </v-list-item-avatar>

          <v-list-item-content>

            <v-list-item-title>{{ session.user_data.first_name }} {{ session.user_data.last_name }}
            </v-list-item-title>
            <v-list-item-subtitle>{{ session.user_data.company }}</v-list-item-subtitle>

          </v-list-item-content>

          <v-list-item-action>
            <theme_switcher type="icon"></theme_switcher>
          </v-list-item-action>

          <v-list-item-action>
            <system_language_selector></system_language_selector>
          </v-list-item-action>

          <v-list-item-action>
            <v-btn icon @click="session.signOut()">
              <v-icon>mdi-logout</v-icon>
            </v-btn>
          </v-list-item-action>


        </v-list-item>

        <v-list-item router :to="'/profile'">
          <v-list-item-title>{{ $t('erp.menu.profile') }}</v-list-item-title>
        </v-list-item>

      </v-list>

    </v-card>


  </v-menu>

</template>


<script>


import {sessionStore} from '@/store/pinia/sessionStore'
import system_language_selector from "./system_language_selector";


import theme_switcher from "./theme_switcher";


export default {

  name: 'user_menu',
  setup() {

    const session = sessionStore();

    return {
      // you can return the whole store instance to use it in the template
      session,
    }

  },

  components: {

    theme_switcher,
    system_language_selector,

  },

  mounted: function () {


  },


  data() {
    return {

      user_data: null,

    }
  },

}

</script>
