<template>

    <div>

        <v-overlay v-if="error500" opacity="50" z-index="99999">
            <v-alert :value="true" type="error">
              {{$t('connection.message.error.error_500')}}
            </v-alert>
        </v-overlay>

        <v-snackbar v-model="snackbar" bottom v-if="message != null" :color="message.type">
            {{ message.text }}
          <template v-slot:action="{ attrs }">
            <v-btn
                text
                v-bind="attrs"
                @click="snackbar = false"
            >
              {{$t('common.close') }}
            </v-btn>
          </template>
        </v-snackbar>

    </div>

</template>


<script>

import { sessionStore } from '@/store/pinia/sessionStore'

    export default {

      setup() {

        const session = sessionStore();

        return {
          // you can return the whole store instance to use it in the template
          session,
        }

      },

        data() {
            return {

                message: null,
                snackbar: false,
                error500: false

            }
        },

        methods: {


            throwMessage: function(message = '', type = 'message', code = 200){

                var self = this;

                self.snackbar = false;
                self.message = {'text': message, 'type':type, 'code':code};
                self.snackbar = true;

            },


        },

        mounted: function () {

            var self = this;

            this.$http.interceptors.request.use(function (config) {

                self.$root.$emit('erp.com.start');

                // Do something before request is sent
                config.request_id = (new Date().getTime()).toString(16);
                return config;

            }, function (error) {

                // Do something with request error
                return Promise.reject(error);

            });

            this.$http.interceptors.response.use(function (response) {

                if(response.data.error != null){
                    self.throwMessage(response.data.error, 'error', response.status);
                }
                 else if(response.data.message != null){
                    self.throwMessage(response.data.message, 'message', response.status);
                }


                return response;

            }, function (error) {


              if(error.code === 'ERR_CANCELED'){return;}

                if(error.response === undefined){

                  self.throwMessage(self.$t('connection.message.error.error_connection_server'),'error');

                }

                if(error.response.status === 401){

                   self.session.signOut();

                } else {


                    self.throwMessage('API Error #'+error.response.status+' – '+error.response.statusText,'error');

                }

            });

        },

    }
</script>