<template>

  <v-main class="auth-view" :class="{'dark': $vuetify.theme.dark}">

    <div class="background-overlay"></div>

    <v-container fluid fill-height>

      <v-layout align-center justify-center>
        <v-flex xs12 sm10 md6 lg6 style="max-width: 300px;">

          <v-card elevation="0">

            <v-card-text>

              <v-form ref="form">

                <div style="max-width: 130px; margin: 0 auto;" class="mb-10 mt-3">
                  <app-logo style=""></app-logo>
                </div>


                <div v-if="session.servers.length == 0 || session.server_selector === true">

                  <div v-if="forced_server">

                    <v-text-field outlined dense hide-details class="mb-3" :label="$t('login.label.server')" disabled
                                  :value="forced_server"></v-text-field>

                  </div>

                  <div v-else>

                    <v-combobox
                        required
                        outlined
                        dense
                        hide-details
                        class="mb-3"
                        v-model="credentials.server"
                        :items="session.servers"
                        :label="$t('login.label.server')"
                        :disabled="session.state === 'authenticating'"
                        v-if="session.servers != null && session.servers.length > 0"

                    ></v-combobox>
                    <v-text-field v-else
                                  required
                                  outlined
                                  dense
                                  hide-details
                                  class="mb-3"
                                  :disabled="session.state === 'authenticating'"
                                  :label="$t('login.label.server')" type="text"
                                  v-model="credentials.server"
                                  :value="credentials.server" @keyup.enter="login()" :clearable="true">
                    </v-text-field>

                  </div>

                </div>

                <v-text-field
                    required
                    outlined
                    dense
                    hide-details
                    class="mb-3"
                    name="login"
                    :label="$t('login.label.username')"
                    type="text"
                    autocomplete="username"
                    :disabled="session.state === 'authenticating'"
                    v-model="credentials.login"
                    :value="credentials.login"
                    @keyup.enter="login()"
                    :clearable="true">
                </v-text-field>


                <v-text-field
                    :disabled="session.state === 'authenticating'"
                    required
                    outlined
                    dense
                    hide-details
                    class="mb-3"
                    :label="$t('login.label.password')"
                    :type="password_type"
                    :append-icon="password_append_icon"
                    v-model="credentials.password"
                    @keyup.enter="login()"
                    :value="credentials.password"
                    :clearable="true"
                    autocomplete="current-password"
                    @click:append="toggle_password_field()"
                ></v-text-field>

                <v-btn
                    color="primary"
                    depressed
                    block
                    @click="login()" :loading="session.state=='authenticating'"
                    :disabled="credentials.login.length < 3 || credentials.password.length < 3">
                  {{ $t('login.label.sign_in') }}
                </v-btn>

              </v-form>

            </v-card-text>




            <!--
            <v-card-text class="text-center pt-0">
              <a href="#">  {{ $t('login.label.password_reset') }}</a>
            </v-card-text>
            -->



            <v-card-actions class="pt-0 px-4">

              <span style="font-size: 12px;">© {{ new Date().getFullYear() }} <a href="https://imbaa.de">IMBAA GmbH</a></span>
              <v-spacer></v-spacer>
              <v-btn icon small @click="session.toggleServerSelector()" v-if="session.servers.length !== 0">
                <v-icon small>mdi-briefcase-arrow-up-down</v-icon>
              </v-btn>
              <theme_switcher type="icon" small></theme_switcher>
              <lang-switch small style="margin: 0 auto; display: inline-block;"></lang-switch>
            </v-card-actions>

          </v-card>




          <div>


            <div class="pa-3 text-center" style="color:#fff;">


              <v-chip x-small label>v{{session.client_version}}</v-chip>

           </div>


            <a href="https://imbaa.de" target="_blank">

              <svg class="imbaa-logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240.69 73.16">

                <g id="logo_element">
                  <path class="cls-1"
                        d="M199.86,70.11c.4,.19,.82,.38,1.23,.56,3.48,1.48,7.17,2.23,11.08,2.23h28.52v-28.65c0-3.99-.74-7.7-2.23-11.14-1.49-3.44-3.52-6.45-6.11-9.04-2.59-2.59-5.6-4.63-9.04-6.11-3.44-1.49-7.15-2.23-11.14-2.23s-7.6,.74-11.08,2.23c-3.18,1.36-6,3.17-8.47,5.45,1.86,2.35,3.4,4.93,4.6,7.71,.4,.93,.75,1.86,1.06,2.81,.49-.65,1.02-1.27,1.6-1.85,1.57-1.57,3.42-2.8,5.54-3.69,2.12-.89,4.37-1.34,6.75-1.34s4.61,.45,6.68,1.34c2.08,.89,3.91,2.12,5.47,3.69,1.57,1.57,2.8,3.39,3.69,5.47,.89,2.08,1.34,4.31,1.34,6.68v17.32h-17.19c-2.38,0-4.63-.45-6.75-1.34-4.25-1.78-7.45-4.98-9.23-9.23-2.41-5.73-.61-11.05-3.57-17.89-.93-2.14-2.07-4.12-3.43-5.94-.82-1.09-1.71-2.13-2.69-3.1-.23-.23-.47-.46-.71-.68-2.42-2.27-5.2-4.08-8.33-5.43-3.44-1.49-7.15-2.23-11.14-2.23s-7.6,.74-11.08,2.23c-3.2,1.36-6.04,3.19-8.52,5.49,1.94,2.41,3.53,5.06,4.77,7.93,.36,.83,.68,1.67,.96,2.52,.48-.64,1-1.24,1.57-1.81,1.57-1.57,3.42-2.8,5.54-3.69,2.12-.89,4.37-1.34,6.75-1.34s4.61,.45,6.68,1.34c2.08,.89,3.91,2.12,5.48,3.69,.58,.58,1.12,1.2,1.6,1.85,.83,1.11,1.53,2.32,2.09,3.63,2.39,5.58,.66,10.95,3.57,17.76,2.79,6.53,7.73,11.72,14.11,14.78"/>
                  <path class="cls-1"
                        d="M91.98,8.18v-2.52c0-3.12,2.55-5.66,5.66-5.66h5.66V15.99h17.31c3.99,0,7.7,.74,11.14,2.23,3.05,1.32,5.76,3.07,8.14,5.25,.3,.28,.6,.57,.9,.86,.91,.91,1.75,1.87,2.53,2.89,1.43,1.87,2.62,3.92,3.58,6.15,3.02,6.98,1.05,11.95,3.44,17.63,.58,1.39,1.31,2.66,2.19,3.82,.46,.61,.96,1.18,1.5,1.72,1.57,1.57,3.42,2.8,5.54,3.69,2.12,.89,4.37,1.34,6.75,1.34h17c1.41,2.34,3.1,4.51,5.06,6.47,1.87,1.87,3.93,3.49,6.15,4.86h-28.2c-3.9,0-7.6-.74-11.08-2.23-3.11-1.33-5.87-3.11-8.3-5.35-.29-.27-.58-.55-.86-.83-.93-.93-1.78-1.91-2.56-2.94-1.44-1.9-2.65-3.97-3.61-6.23-2.93-6.85-.99-11.79-3.44-17.5-.59-1.39-1.34-2.66-2.23-3.82-.45-.58-.93-1.13-1.46-1.65-1.57-1.57-3.4-2.8-5.47-3.69-2.08-.89-4.31-1.34-6.68-1.34h-15.57c-2.72-5.82-7.38-10.5-13.07-13.27v-5.88Z"/>
                  <path class="cls-1"
                        d="M11.3,11.33l.03-11.33H5.66C2.55,0,0,2.55,0,5.66v5.67H11.3ZM92.37,49.26c.36,2.18,.98,4.29,1.84,6.33,1.48,3.48,3.54,6.53,6.17,9.17,2.63,2.63,5.69,4.69,9.17,6.17,3.48,1.49,7.17,2.23,11.08,2.23s7.7-.74,11.14-2.23c3.16-1.36,5.96-3.21,8.4-5.54-1.98-2.45-3.6-5.16-4.85-8.09-.33-.78-.64-1.58-.91-2.38-.49,.66-1.03,1.29-1.62,1.88-1.57,1.57-3.4,2.8-5.47,3.69-2.08,.89-4.31,1.34-6.68,1.34s-4.62-.45-6.75-1.34c-2.12-.89-3.97-2.12-5.54-3.69-1.57-1.57-2.8-3.42-3.69-5.54-.27-.65-.51-1.32-.7-2-1.18-4.23-.63-10.32-1.57-14.88-2.14-10.42-11.42-18.31-22.45-18.31h0c-6.86,0-13.04,3.06-17.25,7.87-4.21-4.82-10.39-7.87-17.25-7.87h0c-12.6,0-22.92,10.31-22.92,22.91v17.84c-.42,2.7-2.79,4.81-5.59,4.81-3.09,0-5.66-2.57-5.66-5.66l.02-40.24H0V55.98c0,9.31,7.62,16.92,16.92,16.92h0c9.31,0,16.92-7.62,16.92-16.92v-17c0-6.35,5.24-11.58,11.58-11.58s11.59,5.23,11.59,11.58v33.92h11.33V38.98c0-6.35,5.24-11.58,11.59-11.58,4.94,0,9.2,3.17,10.85,7.56,1.32,3.5,.9,10.19,1.58,14.3Z"/>
                </g>
              </svg>

            </a>
          </div>



          <!--
          <div style="text-align: center; padding: 10px;">

            <a href="#">Licenses</a>
            <a href="#">Terms and conditions</a>


          </div>
          -->

        </v-flex>

      </v-layout>

    </v-container>





  </v-main>

</template>


<script>

import { sessionStore } from '@/store/pinia/sessionStore'
import langSwitch from './system_language_selector'
import AppLogo from "./AppLogo";
import Theme_switcher from "./theme_switcher";
import ImbaaLogo from '/src/assets/imbaa.svg'

export default {

  name: 'Login',
  components: {Theme_switcher, langSwitch, AppLogo},

  setup() {

    const session = sessionStore();

    return {
      // you can return the whole store instance to use it in the template
      session,
    }

  },

  data() {
    return {

      credentials: {

        server: null,
        login: '',
        password: '',


      },
      show_server_selector: false,
      password_type: 'password',
      forced_server: process.env.VUE_APP_FORCED_SERVER,
    }
  },



  computed: {

    password_append_icon: function(){

      if(this.password_type === 'text'){

        return 'mdi-eye'

      }

      return 'mdi-eye-off';

    }

  },

  methods: {

    toggle_password_field: function(){

      if(this.password_type == 'password'){
        this.password_type = 'text';
        return;
      }

      this.password_type = 'password';

    },

    login: function () {


      this.credentials.server = this.credentials.server.replace(/\s/g, "");
      this.credentials.login = this.credentials.login.replace(/\s/g, "");

      this.session.signIn(this.credentials);

    },

  },


  mounted: function () {

    let self = this;
    let last_login = localStorage.getItem('pling.credentials.last_login');

    self.credentials.server = self.session.server;

    if(last_login !== undefined){
      self.credentials.login = last_login;
    }


    if(self.forced_server) {
      self.credentials.server = null;
      self.credentials.server = this.forced_server;
    }


  },


}


</script>


<style lang="scss" scoped>


.fml {
  opacity: 0.5;
}

.auth-view {


  .imbaa-logo {
    max-width: 50px;
    margin: 15px auto;
    display: block;
    fill: #ffffff;
  }

}

</style>
