import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import store from '@/store'

import APIService from './services/APIService'
import router from '@/router'
import { sync } from 'vuex-router-sync'


import FlagIcon from './custom_components/flag'
Vue.use(FlagIcon);

Vue.prototype.$http = APIService;
Vue.config.productionTip = false;
sync(store, router);

const moment = require('moment');


Vue.use(require('vue-moment'), {moment});

import i18n from './i18n'
import VuetifyConfirm from 'vuetify-confirm'


import { createPinia, PiniaVuePlugin } from 'pinia'

Vue.use(PiniaVuePlugin)
const pinia = createPinia()

var VueScrollTo = require('vue-scrollto');

Vue.use(VueScrollTo,{offset: -200})


Vue.use(VuetifyConfirm, {
  vuetify,
  buttonTrueText: i18n.t('v-confirm.accept-text'),
  buttonFalseText: i18n.t('v-confirm.decline-text'),
  color: 'error',
  icon: 'mdi-warning',
  title: i18n.t('v-confirm.title-text'),
  persistent: true,
  property: '$confirm'
})

import numeral from 'numeral';
import numFormat from 'vue-filter-number-format';

Vue.filter('numFormat', numFormat(numeral));

import VueCurrencyInput from 'vue-currency-input'

Vue.use(VueCurrencyInput, {
  precision: 10,
  locale: 'de',
  distraction_free: true

});

/* eslint-disable no-new */
new Vue({
  vuetify,
  router,
  store,
  i18n,
  pinia,
  render: h => h(App)
}).$mount('#app')
