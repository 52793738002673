<template>

    <div class="theme-switcher">

      <v-tooltip bottom>
        <template v-slot:activator="{ on }">

          <div v-if="type === 'icon'">
          <v-btn v-on="on" :small="small" icon @click="toggleMode();">
            <v-icon :small="small" v-if="!$vuetify.theme.dark">mdi-weather-night</v-icon>
            <v-icon color="yellow" :small="small" v-else>mdi-white-balance-sunny</v-icon>

          </v-btn>


          </div>
          <div v-else>
            <v-btn text :small="small" v-on="on" @click="toggleMode();">{{ $t('settings.theme.label.enable_dark_mode') }}</v-btn>
          </div>


        </template>
        <span  v-if="!$vuetify.theme.dark">{{ $t('settings.theme.label.enable_dark_mode') }}</span>
        <span  v-else>{{ $t('settings.theme.label.enable_light_mode') }}</span>
      </v-tooltip>




      <!--

      <v-tooltip v-if="!$vuetify.theme.dark" bottom>
            <template v-slot:activator="{ on }">
                <v-btn v-on="on" :small="small" icon @click="toggleMode();" v-if="type === 'icon'">
                  <v-icon class="mr-1">mdi-weather-night</v-icon>
                </v-btn>
              <v-btn v-else text :small="small" v-on="on" @click="toggleMode();">{{ $t('settings.theme.label.enable_dark_mode') }}</v-btn>

            </template>
            <span>Dark Mode <v-chip label x-small color="error" class="ml-2">beta</v-chip></span>
        </v-tooltip>

        <v-tooltip v-else bottom>
            <template v-slot:activator="{ on }">
                <v-btn v-on="on" icon :small="small" @click="toggleMode();" v-if="type === 'icon'">
                    <v-icon color="yellow">mdi-white-balance-sunny</v-icon>
                </v-btn>

              <v-btn v-else text :small="small" v-on="on" @click="toggleMode();">{{ $t('settings.theme.label.enable_light_mode') }}</v-btn>

            </template>
            <span>Light Mode</span>
        </v-tooltip>

        -->

    </div>

</template>


<script>
import { sessionStore } from '@/store/pinia/sessionStore'

    export default {

        name: 'theme_switcher',
        setup() {

          const session = sessionStore();

          return {
            // you can return the whole store instance to use it in the template
            session,
          }

        },

      props: {

        type: {default: 'text'},
        small: Boolean,

      },


        methods: {


            toggleMode: function () {


              this.session.toggleDarkMode();
              this.$vuetify.theme.dark = this.session.dark_mode;



            }

        }

    }

</script>

<style lang="scss" scoped>

.theme-switcher {
  display: inline-block;
}

</style>
